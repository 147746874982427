import React, { useEffect } from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage, BgImage } from "gbimage-bridge"
import { BackgroundImage } from "gatsby-background-image"

// Helpers
import { animate, cutOffExcerpt, trackCta } from "src/helpers"

// Components
import BackgroundBlobs from "../components/images/BackgroundBlobs"
import Footer from "src/components/footer"
import FooterCta from "src/components/footerCta"
import FreeTrial from "src/components/freeTrial"
import Header from "src/components/header"
import Layout from "src/components/layout"
import SEO from "src/components/seo"
import Stars from "src/components/stars"
import SignupButton from "src/components/signupButton"

// Data
import capterraReviews from "src/content/home/capterraReviews"

// Styles
import "src/styles/templates/staff-holiday.scss"
import "src/styles/animations/home.scss"

// SEO
const title = "Staff leave planner for teams and SMEs"
const description =
  "Timetastic: the simple staff leave planner for teams and SMEs. Streamline your staff holiday booking system & move away from spreadsheets."

// JSX
const Home = (props) => {
  const reviews = props.data.allReviews.nodes
  const reviewStats = reviews[0].stats

  // Animate any elements with a class of 'is-unanimated'
  useEffect(() => {
    animate()
  }, [])

  return (
    <>
      <SEO title={title} description={description} />
      <Layout>
        <Header />
        <FooterCta />
        <main className="c-staff-holidays-main">
          <section className="c-section c-section-us-stars is-unanimated">
            <div className="u-inner u-inner--l">
              <div className="c-staff-holidays-quotes">
                <div className="c-staff-holidays-quote">
                  <p>“An absolute no-brainer”</p>
                </div>
                <div className="c-staff-holidays-quote">
                  <p>“Fantastic super simple product!”</p>
                </div>
                <div className="c-staff-holidays-quote">
                  <p>“Everyone here loves it”</p>
                </div>
              </div>
            </div>
          </section>
          <section
            className="c-section c-staff-holidays-hero is-unanimated"
            id="hero"
          >
            <div className="u-inner u-inner--l c-staff-holidays-hero__inner">
              <div>
                <h1 className="h h2 c-staff-holidays-hero__title">
                  Your all-in-one staff leave planner
                </h1>
                <p>
                  <strong>Without Timetastic,</strong> keeping track of holiday
                  requests is a nightmare. Managing employee holiday leave takes
                  up time, thanks to cluttered spreadsheets and shared
                  calendars. It’s hard keeping your holiday planner up to date,
                  and it’s yet another admin burden to weigh you down.
                </p>
                <p>
                  <strong>But, with Timetastic,</strong> you’ve got a slick
                  staff leave planner to help you manage holiday requests on
                  desktop or mobile. All your annual leave is tracked and
                  recorded, and your calendars are instantly updated. It’s a
                  huge weight off your shoulders.
                </p>
                <p>
                  Discover the magic of a staff leave planner that provides
                  seamless integration with your calendar today.
                </p>
                <div className="c-staff-holidays-hero__cta u-text-centre">
                  <SignupButton
                    buttonCopy="Pricing & free trial"
                    trackCtaLabel="StaffLeavePlannerSignupClick"
                  />

                  <p className="c-free-trial__no-link">
                    Free for one month - no card required
                  </p>
                </div>
                <p className="c-staff-holidays-hero__loop-arrow">
                  Let’s see how Timetastic works...
                </p>
              </div>
              <div className="c-staff-holidays-hero__images is-unanimated">
                <BackgroundBlobs blobType="howHeroBlobs">
                  <StaticImage
                    src="../images/no-spreadsheets-tablet.jpg"
                    width={780}
                    alt="Tablet wallchart"
                    placeholder="blurred"
                    layout="constrained"
                    loading="eager"
                    className="c-staff-holidays-hero__tablet"
                  />
                  <StaticImage
                    src="../images/hero-mobile-booking.jpg"
                    width={197}
                    alt="Mobile booking"
                    placeholder="blurred"
                    layout="constrained"
                    loading="eager"
                    className="c-staff-holidays-hero__mobile"
                  />
                </BackgroundBlobs>
              </div>
              <div className="c-staff-holidays-hero__mobile-s">
                <StaticImage
                  src="../images/no-spreadsheets-mobile-s.jpg"
                  width={359}
                  alt="Mobile calendar"
                  placeholder="blurred"
                  layout="constrained"
                  loading="eager"
                />
              </div>
            </div>
          </section>
          <section className="c-section is-animated">
            <div className="u-inner u-inner--l">
              <div className="c-staff-holidays-features">
                <div className="c-staff-holidays-feature c-staff-holidays-feature-list">
                  <div className="">
                    <div className="c-staff-holidays-feature-list__blob-01">
                      <BackgroundBlobs blobType="longlistBlobs">
                        <div className="u-inner u-inner--l">
                          <div className="c-staff-holidays-feature-grid__item">
                            <div className="c-home-grid__annotation c-staff-holidays-feature-grid__image-remaining">
                              <span className="c-home-grid__annotation--text c-home-grid__annotation--top c-home-grid__annotation--hide">
                                Keep track of allowances
                              </span>
                              <StaticImage
                                src="../images/calendars-remaining.jpg"
                                width={321}
                                alt="Remaining allowance"
                                placeholder="blurred"
                                layout="constrained"
                                className="c-staff-holidays-feature-grid__image"
                              />
                            </div>
                            <div className="c-home-grid__annotation c-staff-holidays-feature-grid__image-months">
                              <span className="c-home-grid__annotation--text c-home-grid__annotation--top c-home-grid__annotation--hide">
                                Time off at a glance
                              </span>
                              <StaticImage
                                src="../images/calendars-months.jpg"
                                width={657}
                                alt="Calendar months"
                                placeholder="blurred"
                                layout="constrained"
                                className="c-staff-holidays-feature-grid__image"
                              />
                            </div>
                            <div className="c-home-grid__annotation c-staff-holidays-feature-grid__image-months-2">
                              <span className="c-home-grid__annotation--text c-home-grid__annotation--top c-home-grid__annotation--hide">
                                Time off at a glance
                              </span>
                              <StaticImage
                                src="../images/calendars-months-2.jpg"
                                width={625}
                                alt="Calendar months"
                                placeholder="blurred"
                                layout="constrained"
                                className="c-staff-holidays-feature-grid__image"
                              />
                            </div>
                            <div className="c-home-grid__annotation c-staff-holidays-feature-grid__image-summary">
                              <span className="c-home-grid__annotation--text c-home-grid__annotation--top c-home-grid__annotation--hide">
                                Summary of time off
                              </span>
                              <StaticImage
                                src="../images/calendars-summary.jpg"
                                width={586}
                                alt="Leave summary"
                                placeholder="blurred"
                                layout="constrained"
                                className="c-staff-holidays-feature-grid__image"
                              />
                            </div>
                            <div className="c-home-grid__annotation c-staff-holidays-feature-grid__image-approved">
                              <StaticImage
                                src="../images/pto-calendars-approved.jpg"
                                width={586}
                                alt="Request approved"
                                placeholder="blurred"
                                layout="constrained"
                                className="c-staff-holidays-feature-grid__image"
                              />
                              <span className="c-home-grid__annotation--text c-home-grid__annotation--bottom c-home-grid__annotation--hide">
                                Instant email notifications
                              </span>
                            </div>
                            <StaticImage
                              src="../images/calendars-breakdown.jpg"
                              width={586}
                              alt="Your time off"
                              placeholder="blurred"
                              layout="constrained"
                              className="c-staff-holidays-feature-grid__image c-staff-holidays-feature-grid__image-breakdown"
                            />
                            <StaticImage
                              src="../images/calendars-trends.jpg"
                              width={586}
                              alt="Annual trends"
                              placeholder="blurred"
                              layout="constrained"
                              className="c-staff-holidays-feature-grid__image c-staff-holidays-feature-grid__image-trends"
                            />
                          </div>
                        </div>
                      </BackgroundBlobs>
                    </div>
                  </div>
                  <div>
                    <h3 className="h h4">
                      A simple but beautiful employee booking system
                    </h3>
                    <ol>
                      <li className="is-unanimated">
                        <h4 className="h h5">
                          A slick workflow for seamless holiday booking
                        </h4>
                        <p>
                          You won’t need any scrappy holiday request forms
                          anymore. Simply log in to Timetastic, check the staff
                          leave planner and click the days you want off work.
                          Done in an instant.
                        </p>
                      </li>
                      <li className="is-unanimated">
                        <h4 className="h h5">
                          Take back control of managing staff holiday leave
                        </h4>
                        <p>
                          Every holiday request is recorded and tracked as it’s
                          happening, giving you live notifications the second a
                          request is made. You can see a tally of how many days
                          off anyone has taken and what balance they have left.
                        </p>
                      </li>
                      <li className="is-unanimated">
                        <h4 className="h h5">An up-to-date holiday calendar</h4>
                        <p>
                          Feed all your holiday bookings directly into your
                          regular calendar. The instant anyone books a holiday —
                          bang, calendars and schedules are all updated.
                        </p>
                      </li>
                      <li className="is-unanimated">
                        <h4 className="h h5">
                          Plan ahead and cover any leave with ease
                        </h4>
                        <p>
                          Tired of coming to work and wondering where somebody
                          is? Take a glance at your interactive staff leave
                          planner and you’ll have daily and weekly updates at
                          your fingertips, keeping you in the know.
                        </p>
                      </li>
                      <li className="is-unanimated">
                        <h4 className="h h5">An end to messy spreadsheets</h4>
                        <p>
                          You’ll get so much time back to yourself. You won’t
                          have to fiddle about with awkward spreadsheets, and
                          your team won’t need to keep asking how much holiday
                          allowance they have left.
                        </p>
                      </li>
                      <li className="is-unanimated">
                        <h4 className="h h5">
                          And you’ll never miss a birthday again
                        </h4>
                        <p>
                          The icing on the cake! Timetastic will even tell you
                          when it’s your colleague’s birthday, so you can
                          organise a nice card or cake. 🎂
                        </p>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="c-section c-us-features-gradient">
            <div className="u-inner u-inner--l">
              <div className="is-unanimated u-text-center u-text-left-mobile">
                <h2 className="h h2">
                  It’s got everything you need to{" "}
                  <span className="u-bgcolor-gradient u-bgcolor-gradient-text">
                    book, track and manage staff holidays
                  </span>
                </h2>
                <p>
                  The first thing you’ll see in Timetastic is your new,
                  interactive wall chart. At a glance you can see who’s in and
                  who’s not. Click to request time off, use it to plan ahead,
                  make sure busy periods are covered and avoid any clashes.
                </p>
                <p>
                  Take a peek at the interactive chart below and check out how
                  your staff leave planner interface would look.
                </p>

                <div className="c-home-grid__annotation">
                  <span className="c-home-grid__annotation--text c-home-grid__annotation--top-left c-home-grid__annotation--hide">
                    Your team
                  </span>
                  <span className="c-home-grid__annotation--text c-home-grid__annotation--top-right c-home-grid__annotation--hide">
                    Book time off
                  </span>
                  <StaticImage
                    src="../images/live-wallchart.jpg"
                    width={1200}
                    alt="Wallchart"
                    placeholder="blurred"
                    layout="constrained"
                    className="c-staff-holidays-feature-list-grid__image c-staff-holidays-feature-list-grid__image-wallchart"
                  />
                  <StaticImage
                    src="../images/live-wallchart-mobile.jpg"
                    width={440}
                    alt="Wallchart"
                    placeholder="blurred"
                    layout="constrained"
                    className="c-staff-holidays-feature-list-grid__image c-staff-holidays-feature-list-grid__image-wallchart-s"
                  />
                  <span className="c-home-grid__annotation--text c-home-grid__annotation--bottom-mid-right c-home-grid__annotation--hide">
                    Pending request
                  </span>
                </div>
              </div>
            </div>
          </section>

          {/* Insert SEO text block here */}

          {/* Reviews list */}
          <section
            className="c-section c-staff-holidays-reviews u-text-center u-text-left-mobile"
            id="reviews"
          >
            <div className="u-inner is-unanimated">
              <h2 className="h h2">
                Everyone{" "}
                <span className="u-bgcolor-gradient u-bgcolor-gradient-text">
                  {" "}
                  loves using Timetastic
                </span>
              </h2>
            </div>
            <div className="u-inner u-inner--800 is-unanimated">
              <p className="">
                There are currently over 200,000 people using Timetastic’s staff
                leave planner to manage staff absences efficiently. Instead of
                manually updating spreadsheets and calendars, they open up
                Timetastic to check their holiday allowance. Then it’s just a
                few clicks to book a day off.
              </p>

              <ul className="c-reviews-list">
                {capterraReviews.map((review, index) => (
                  <li
                    className="c-reviews-list__item is-unanimated"
                    key={`review-l-${index}`}
                  >
                    <div className="c-reviews-list__content">
                      <p>‘{review.review}’</p>
                    </div>
                    <div className="c-reviews-list__avatar">
                      <img
                        loading="lazy"
                        src={review.avatar}
                        width="48px"
                        height="48px"
                        alt={review.reviewer}
                      />
                    </div>
                    <div className="c-reviews-list__reviewer">
                      <a
                        className="c-reviews-list__link"
                        href={review.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => {
                          trackCta("TestimonialsOutboundLinkUS")
                        }}
                      >
                        {review.reviewer}
                      </a>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </section>

          {/* Reviews carousel */}
          <section className="c-section c-staff-holidays-reviews-grid is-unanimated">
            <div className="u-inner u-inner--l">
              <div className="c-reviews-section">
                <div className="c-reviews-section__top u-text-center u-text-left-mobile">
                  <h2 className="h h3">
                    Discover what our customers have to say
                  </h2>
                  <div
                    aria-describedby={`Rated: ${
                      Math.ceil(reviewStats.average_rating * 2) / 2
                    } stars out of 5`}
                    style={{ color: "#fec000" }}
                  >
                    <Stars
                      namespace="total-reviews"
                      numberOfStars={5}
                      size={24}
                    />
                  </div>
                  <div>
                    <a
                      className="u-em-link u-hover-link"
                      href="https://www.reviews.co.uk/company-reviews/store/timetastic"
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => {
                        trackCta("HomeReviewsOutboundLinkUS")
                      }}
                    >
                      <span>
                        View all {reviewStats.total_reviews.toLocaleString()}{" "}
                        reviews{" "}
                      </span>
                      <svg
                        aria-hidden="true"
                        className="c-more-icon"
                        focussable="false"
                        height="16"
                        viewBox="0 0 20 16"
                        width="20"
                      >
                        <use xlinkHref="#svg-arrow" />
                      </svg>
                    </a>
                  </div>
                </div>
                <div className="c-reviews">
                  {reviews.map((review, index) => (
                    <div
                      className="c-review"
                      key={`review-${review.store_review_id}-${review.user_id}`}
                    >
                      <div className="c-review__content">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: cutOffExcerpt(review.comments, 112),
                          }}
                        ></p>
                      </div>
                      <div
                        aria-describedby={`Rated: ${review.rating} stars out of 5`}
                        className="c-review__stars"
                      >
                        <Stars
                          namespace={`${review.store_review_id}-${review.user_id}`}
                          numberOfStars={parseFloat(review.rating)}
                        />
                      </div>
                      <div className="c-review__reviewer">
                        {review.reviewer.first_name} {review.reviewer.last_name}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </section>

          <section className="c-section">
            <div className="u-inner u-inner--l">
              <div className="c-staff-holidays-features-blocks">
                <div className="c-staff-holidays-feature-block c-staff-holidays-feature-block__regions is-unanimated">
                  <div>
                    <h3 className="h h3 u-text-center">
                      Staff booking systems you can use globally
                    </h3>
                  </div>

                  <img
                    loading="lazy"
                    alt="Public holidays from 250 countries"
                    className="c-features-grid__imagex"
                    src="/images/staff-holiday/global-public-holidays.svg"
                    width="516"
                    height="269"
                  />
                  <p className="u-text-center">
                    Every country is covered, from Andorra to Zambia. Timetastic
                    supports over 3,000 regions from around the world.
                  </p>
                </div>
                <div className="c-staff-holidays-feature-block c-staff-holidays-feature-block__leave is-unanimated">
                  <ul className="u-text-center">
                    <li className="h h3">Holidays</li>
                    <li className="h h3">Sickness</li>
                    <li className="h h3">Maternity</li>
                    <li className="h h3">Paternity</li>
                    <li className="h h3">Unpaid leave</li>
                    <li className="h h3">Bereavement</li>
                    <li className="h h3">Compassionate</li>
                    <li className="h h3">Jury duty</li>
                    <li className="h h3">Personal day</li>
                    <li className="h h3">Work from home</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <section className="c-section u-text-center">
            <div className="u-inner u-inner--l is-unanimated">
              <div className="c-staff-holidays-feature-block c-staff-holidays-feature-block__pricing">
                <h2 className="h h2">Free for your first 30 days</h2>
                <p>
                  You can use Timetastic completely free for 30 days, you don’t
                  need to enter any payment details or phone numbers.
                </p>
                <p>
                  So you can start booking staff holidays right away, see how it
                  goes, check it works for you, no questions asked.
                </p>
                <p className="c-staff-holidays-feature-block__pricing-cta">
                  If after 30 days it’s looking good, then Timetastic is just
                  £1.20 per user/month.
                </p>
              </div>
            </div>
          </section>

          <FreeTrial
            body="Our employee holiday booking system has helped thousands of businesses manage holiday leave. Since 2012, we’ve partnered with web design agencies, car dealers, online retailers, charities, and GP surgeries - with over 8,000 teams and small businesses just like yours using our booking system."
            buttonCopy="Start booking holidays now"
            title="We’ve helped thousands of businesses with staff leave"
            trackCtaLabel="StaffHolidaySignUpFooter"
            imgClass="c-free-trial__user-graph"
          />

          <section className="c-section c-features-faq">
            <div className="u-inner u-inner--l is-unanimated">
              <h2 className="h h2">Frequently asked questions</h2>
              <ul>
                <li>
                  <h3 className="h h4">
                    What advantages does Timetastic's employee holiday booking
                    system offer for effective resource management?
                  </h3>
                  <p>
                    Managing resources can be a fine balancing act for a
                    business, that’s why our holiday booking system can be your
                    best friend. Our handy interactive wall chart provides great
                    visibility so you can check who’s in and who’s not. A quick
                    glance at the chart and you’ll have a complete overview to
                    help you allocate your resources efficiently.
                  </p>
                </li>
                <li>
                  <h3 className="h h4">
                    Will a staff leave planner improve employee satisfaction?
                  </h3>
                  <p>
                    Clarity is extremely important for employees. With
                    Timetastic, you’ll be able to offer this in abundance with a
                    transparent and accessible holiday booking planner that’s
                    super easy to use. Staff will know exactly how much holiday
                    allowance they have left, and be able to see when other
                    colleagues are off too to allow for seamless planning and
                    coordination of tasks.
                  </p>
                </li>
                <li>
                  <h3 className="h h4">
                    How can Timetastic’s staff leave planner help boost
                    productivity for my business?
                  </h3>
                  <p>
                    Say goodbye to scheduling conflicts and panicking when
                    trying to arrange cover for absences or leave. Our system
                    takes away the stress by combining all your holiday bookings
                    into your regular calendar, giving you total visibility the
                    second a leave request is lodged. Providing visibility for
                    both you and your staff on holiday leave means everybody is
                    aligned and you’ll have transparency to plan well ahead of
                    time.
                  </p>
                </li>
              </ul>
            </div>
          </section>
        </main>
        <Footer />
      </Layout>
    </>
  )
}

export default Home

export const query = graphql`
  query {
    placeholderImage: file(
      relativePath: { eq: "../images/hero-blobs-landscape.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 100, formats: [AUTO, WEBP])
      }
    }
    allReviews {
      nodes {
        comments
        rating
        reviewer {
          first_name
          last_name
        }
        stats {
          average_rating
          total_reviews
        }
        store_review_id
        user_id
      }
    }
  }
`
